import React from 'react';

import { Form, Spin } from 'antd';
import { merge } from 'lodash';

import { useParams } from 'react-router-dom';

import { FieldGroupRender, getInitialValues } from '@components/FieldGroupRender';
import { useGetOrderQuery, useGetProcessQuery } from '@pages/Processes/hooks';
import { useOrderItems } from '@pages/Processes/pages/Orders/providers';

import { ItemsTable } from '../ItemsTable';

interface IProps {
  onFinish: (data: any) => void;
}

export const OrderForm: React.FC<IProps> = (props) => {
  const [form] = Form.useForm();
  const params = useParams<{ orderId: string; processId: string }>();

  const { data: orderData, isLoading: orderLoading } = useGetOrderQuery(params?.orderId || '');
  const { data: processData, isLoading: processLoading } = useGetProcessQuery(params.processId);
  const { items, setArtifactUid, setItems } = useOrderItems();
  const itemsArtifactUid = processData?.order_structure?.items?.find((el: any) => el?.data?.type === 'order_items')
    ?.data?.uid;
  const catalogUID = (orderData as any)?.field_groups?.general?.catalog?.uid;
  const itemsAvailable = itemsArtifactUid ? !!orderData?.$artifacts?.[itemsArtifactUid]?.update : false;

  React.useEffect(() => {
    if (itemsArtifactUid) {
      const artifactData = orderData?.artifacts?.[itemsArtifactUid] as any;
      setItems(artifactData?.items);
      setArtifactUid(itemsArtifactUid);
    }
  }, [orderData, itemsArtifactUid]);

  const handleSubmit = async (values: any): Promise<void> => {
    props.onFinish({
      order_uid: orderData?.uid || '',
      data: merge(values, {
        artifacts: {
          [`${itemsArtifactUid}`]: {
            items: items?.map((item: any) => ({ product_uid: item?.product?.uid, quantity: item?.quantity })),
          },
        },
        version: orderData?.version,
      }),
    });
  };

  const EDITABLE_ITEMS =
    processData?.order_structure?.items.filter((el) => {
      if (el.type === 'field_group' && !orderData?.$field_groups?.[el.data.alias || el.data.uid].update) {
        return;
      }
      return el;
    }) || [];

  if (orderLoading || processLoading) {
    return <Spin />;
  }

  console.log({ ...getInitialValues(orderData, processData) });

  return (
    <>
      <Form
        form={form}
        name="updateOrder"
        id="updateOrder"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 8 }}
        initialValues={{ ...getInitialValues(orderData, processData) }}
        onFinish={handleSubmit}>
        {processData && <FieldGroupRender form={form} items={EDITABLE_ITEMS} order_uid={params.orderId} />}
        {/* <Form.Item noStyle shouldUpdate> */}
        {/*   {() => ( */}
        {/*     <Typography> */}
        {/*       <pre>{JSON.stringify(form.getFieldsValue(), null, 2)}</pre> */}
        {/*     </Typography> */}
        {/*   )} */}
        {/* </Form.Item> */}
      </Form>
      {itemsAvailable && <ItemsTable catalogUID={catalogUID} />}
    </>
  );
};
