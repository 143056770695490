import styled from 'styled-components';

import { Paper } from '@components/Paper';

export const Container = styled(Paper)`
  margin: 24px;
  padding: 24px;

  .ant-table-tbody {
    .ant-table-cell:has(.success) {
      background-color: #ebf8f2;
    }
    .ant-table-cell:has(.notSuccess) {
      background-color: #fabeb9;
    }
  }

  .ant-table-tbody .hiddenThead {
    .ant-table-thead {
      display: none;
    }
  }
`;
