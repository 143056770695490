import React, { FC } from 'react';

import { Button, Divider, Flex, Form, FormInstance, Table, theme } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { MappingSettingsDTO, PreviewDTO } from '@app/models/orders';

import { IRunFormValues } from '../../interfaces';

import { Summary } from './components/Summary';
import { getColumns } from './data/getColumns';
import { getOptions } from './data/getOptions';

interface IProps {
  settingsData?: MappingSettingsDTO;
  previewData?: PreviewDTO;
  isLoading?: boolean;
  form: FormInstance;
  onFinish: (values: IRunFormValues) => void;
}

export const Tables: FC<IProps> = ({ settingsData, form, onFinish, previewData, isLoading = false }) => {
  const orderImportsT = useTranslation(NSKeys.orderImports);
  const themeToken = theme.useToken();
  const options = getOptions(settingsData);

  return (
    <Form<IRunFormValues> form={form} onFinish={onFinish}>
      <Flex vertical gap={15}>
        <>
          <Divider orientation="left" plain>
            {orderImportsT.t('previewTableItemsTitle')}
          </Divider>
          <Table
            className="hiddenThead"
            size="small"
            columns={getColumns(previewData?.items)}
            scroll={{ x: 'auto' }}
            dataSource={previewData?.items}
            loading={isLoading}
            pagination={false}
            summary={(pageData) => <Summary prefix="itemsMapping" data={pageData[0]} options={options.items} />}
          />
          <Divider orientation="left" plain>
            {orderImportsT.t('previewTableOrdersTitle')}
          </Divider>
          <Table
            className="hiddenThead"
            size="small"
            columns={getColumns(previewData?.orders)}
            scroll={{ x: 'auto' }}
            dataSource={previewData?.orders}
            loading={isLoading}
            pagination={false}
            summary={(pageData) => <Summary prefix="ordersMapping" data={pageData[0]} options={options.orders} />}
          />
        </>
      </Flex>

      <Button type="primary" htmlType="submit" size="middle" loading={isLoading} style={{ margin: '20px 0' }}>
        {orderImportsT.t('runButton')}
      </Button>
    </Form>
  );
};
