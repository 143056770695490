import React from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, theme, Typography } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { formatDateAndTimeShort } from '@app/services/date';

import { CardStyled, ListItemStyled } from './styles';

export interface Task {
  id: string;
  columnId: string;
  content: string;
  version: string;
  num: number;
  // created_at: string;
  permissionView?: boolean;
  available_statuses_uids: string[];
}

interface TaskCardProps {
  task: Task;
  isOverlay?: boolean;
  onCardClick?: (uid: string) => void;
}

export type TaskType = 'Task';

export interface TaskDragData {
  type: TaskType;
  task: Task;
}

export function TaskCard({ task, isOverlay, onCardClick }: TaskCardProps) {
  const { token } = theme.useToken();
  const orderT = useTranslation(NSKeys.order);
  const { setNodeRef, attributes, listeners, transform, transition, isDragging } = useSortable({
    id: task.id,
    data: {
      type: 'Task',
      task,
    } satisfies TaskDragData,
    attributes: {
      roleDescription: 'Task',
    },
  });

  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
    opacity: isDragging ? '0.4' : '1',
    border: isOverlay ? `1px solid ${token.colorPrimary}` : '',
  };

  return (
    <CardStyled ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {task.permissionView && onCardClick ? (
        <ListItemStyled style={{ cursor: 'pointer', display: 'inline-block' }} onClick={() => onCardClick(task.id)}>
          <Typography.Link className="listItemTitle">
            {orderT.t('blockInfo.num')} {task.num}
          </Typography.Link>
        </ListItemStyled>
      ) : (
        <ListItemStyled>
          <Typography.Text type="secondary" className="listItemTitle">
            {orderT.t('blockInfo.num')} <Typography.Text>{task.num}</Typography.Text>
          </Typography.Text>
        </ListItemStyled>
      )}
      {/* <ListItemStyled> */}
      {/*   <Typography.Text type="secondary" className="listItemTitle"> */}
      {/*     {orderT.t('blockInfo.created_at')}: */}
      {/*   </Typography.Text> */}
      {/*   <Typography.Text>{formatDateAndTimeShort(task.created_at)}</Typography.Text> */}
      {/* </ListItemStyled> */}
    </CardStyled>
  );
}
