import React, { FC, useMemo, useState } from 'react';

import {
  DndContext,
  type DragEndEvent,
  type DragOverEvent,
  DragOverlay,
  type DragStartEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { Flex, theme, Typography } from 'antd';
import { createPortal } from 'react-dom';

import { useNavigate } from 'react-router-dom';

import { RetrieveOrderSchema, RetrieveProcessSchema } from '@app/models/orders';

import { BoardColumn } from './BoardColumn';

import { type Task, TaskCard } from './TaskCard';
import { coordinateGetter } from './multipleContainersKeyboardPreset';
import { StageContainer } from './styles';
import { hasDraggableData } from './utils';

interface IProps {
  isLoading: boolean;
  boardData: RetrieveProcessSchema;
  orders: RetrieveOrderSchema[];
  onChangeStatus: (order_uid: string, status_uid: string, version: string) => void;
}

export const KanbanBoard: FC<IProps> = ({ orders, boardData, isLoading, onChangeStatus }) => {
  const navigate = useNavigate();
  const { token } = theme.useToken();
  const [tasks, setTasks] = useState<Task[]>(
    orders?.map((order) => ({
      columnId: order.current_status_uid || '',
      id: order?.uid || '',
      content: order.uid || '',
      version: order.version || '',
      num: order.num,
      // created_at: order.created_at,
      permissionView: order.$permissions.view,
      available_statuses_uids: order.$available_statuses.map((el) => el.uid),
    }))
  );
  const [activeTask, setActiveTask] = useState<Task | null>(null);
  const columnsId = useMemo(
    () =>
      boardData.stages
        .map((el) => el.statuses)
        .flat()
        .filter((el) => !!el?.uid)
        .map((el) => el?.uid || ''),
    [boardData]
  );
  React.useEffect(() => {
    setTasks(
      orders?.map((order: any) => ({
        columnId: order.current_status_uid || '',
        id: order?.uid || '',
        content: order.uid || '',
        num: order?.field_groups?.['general']?.['num'],
        created_at: order?.field_groups?.['general']?.['created_at'],
        permissionView: order.$permissions.view,
        version: order.version,
        available_statuses_uids: order.$available_statuses.map((el: any) => el.uid),
      }))
    );
  }, [orders]);

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 0.1 } }),
    useSensor(TouchSensor, { activationConstraint: { distance: 0.1 } }),
    useSensor(KeyboardSensor, {
      coordinateGetter: coordinateGetter,
    })
  );

  const handleCardClick = (uid: string) => {
    navigate(uid);
  };

  function onDragStart(event: DragStartEvent) {
    if (!hasDraggableData(event.active)) return;
    const data = event.active.data.current;

    if (data?.type === 'Task') {
      setActiveTask(data.task);
      return;
    }
  }

  function onDragEnd(event: DragEndEvent) {
    const { over } = event;
    const overData = over?.data?.current;
    const originColumnId = orders.find((el) => el.uid === activeTask?.id)?.current_status_uid;

    if (over && activeTask) {
      const isOverAColumn = event.over?.data?.current?.type === 'Column';
      const isOverATask = event.over?.data?.current?.type === 'Task';

      // column drop logic
      if (isOverAColumn && activeTask) {
        if (originColumnId !== activeTask.columnId) {
          console.log(activeTask);
          // console.log('drop', activeTask.id, 'to', activeTask.columnId);
          onChangeStatus(activeTask.id, activeTask.columnId, activeTask.version);
        }
      }

      // task drop logic
      if (isOverATask && overData) {
        // if drop to new column
        if (event.over?.id === event.active.id && originColumnId !== overData?.task?.columnId) {
          // console.log('drop', activeTask.id, 'to', overData?.task?.columnId);
          console.log(activeTask);
          onChangeStatus(activeTask.id, overData?.task?.columnId, activeTask.version);
        }
      }
    }

    setActiveTask(null);
  }

  function onDragOver(event: DragOverEvent) {
    const { active, over } = event;

    if (!over) return;

    const activeId = active.id;
    const overId = over.id;

    if (activeId === overId) return;

    if (!hasDraggableData(active) || !hasDraggableData(over)) return;

    const activeData = active.data.current;
    const overData = over.data.current;

    const isActiveATask = activeData?.type === 'Task';
    const isOverATask = overData?.type === 'Task';

    if (isActiveATask && isOverATask) {
      const overIndex = tasks.findIndex((t) => t.id === overId);
      const overTask = tasks[overIndex];
      // over column not available
      if (!activeTask?.available_statuses_uids.some((el) => el === overTask.columnId)) {
        return;
      }

      setTasks((tasks) => {
        const activeIndex = tasks.findIndex((t) => t.id === activeId);
        const overIndex = tasks.findIndex((t) => t.id === overId);
        const activeTask = tasks[activeIndex];
        const overTask = tasks[overIndex];
        if (activeTask && overTask && activeTask.columnId !== overTask.columnId) {
          activeTask.columnId = overTask.columnId;
          return arrayMove(tasks, activeIndex, overIndex - 1);
        }

        return arrayMove(tasks, activeIndex, overIndex);
      });
    }

    const isOverAColumn = overData?.type === 'Column';

    // Im dropping a Task over a column
    if (isActiveATask && isOverAColumn) {
      // over column not available
      if (!activeTask?.available_statuses_uids.some((el) => el === overId)) {
        return;
      }
      setTasks((tasks) => {
        const activeIndex = tasks.findIndex((t) => t.id === activeId);
        const activeTask = tasks[activeIndex];
        if (activeTask) {
          activeTask.columnId = overId as string;
          return arrayMove(tasks, activeIndex, activeIndex);
        }
        return tasks;
      });
    }
  }

  return (
    <div className="kanban">
      <DndContext sensors={sensors} onDragStart={onDragStart} onDragEnd={onDragEnd} onDragOver={onDragOver}>
        <Flex vertical={false} wrap={false} gap={10} style={{ position: 'relative' }}>
          <SortableContext items={columnsId}>
            {boardData.stages.map((stage) => (
              <Flex key={stage.uid} align="start">
                <Flex vertical className="stage-wrapper" style={{ height: '100%' }}>
                  <StageContainer align="center" justify="start" $colorBgBase={token.colorBgBase}>
                    <Typography.Title level={5} style={{ margin: 0 }} type="secondary">
                      {stage.name}
                    </Typography.Title>
                  </StageContainer>
                  <Flex gap={12} className="status-wrapper" style={{ height: '100%' }}>
                    {stage.statuses?.map((col) => (
                      <BoardColumn
                        isDisabled={
                          activeTask ? !activeTask?.available_statuses_uids?.some((el) => el === col.uid) : false
                        }
                        key={col.uid}
                        column={{ title: col.name, id: col.uid || '', color: col.color }}
                        tasks={tasks.filter((task) => task.columnId === col.uid)}
                        onCardClick={handleCardClick}
                      />
                    ))}
                  </Flex>
                </Flex>
              </Flex>
            ))}
          </SortableContext>
        </Flex>

        {'document' in window &&
          createPortal(
            <DragOverlay>{activeTask && <TaskCard task={activeTask} isOverlay />}</DragOverlay>,
            document.body
          )}
      </DndContext>
    </div>
  );
};
