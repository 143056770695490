import { useQuery } from '@tanstack/react-query';

import Api from '@app/services/api';

export const useGetOrderImport = (uid: string) => {
  return useQuery({
    queryKey: ['getOrderImport', uid],
    queryFn: () => Api.orders.getOrderImport(uid),
    enabled: !!uid,
  });
};
