import React from 'react';

import { DownloadOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import Api from '@app/services/api';
import { getHostName } from '@app/services/httpClient';

interface IProps {
  uid: string;
  fileName?: string;
}

export const DownloadButton: React.FC<IProps> = ({ uid, fileName }) => {
  const commonT = useTranslation(NSKeys.common);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleDownload = async () => {
    if (isLoading) return;

    try {
      setIsLoading(true);
      const { signature } = await Api.orders.postGetSignature(uid);
      const link = document.createElement('a');
      link.download = `orders-${new Date().getUTCDate()}`;
      link.href = `${getHostName()}/orders/uploaded-files/${uid}/download/${signature}`;
      link.click();
      link.remove();
    } catch (e) {
      message.open({
        type: 'error',
        content: commonT.t('error400'),
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (fileName) {
    return (
      <Button type="link" loading={isLoading} onClick={handleDownload} size="small">
        {fileName}
      </Button>
    );
  }

  return <Button loading={isLoading} icon={<DownloadOutlined />} onClick={handleDownload} size="middle" />;
};
