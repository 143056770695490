import { useMutation, useQueryClient } from '@tanstack/react-query';

import { message } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { ActionKind, ApplyActionSchema } from '@app/models/orders';
import Api from '@app/services/api';
import { TABS } from '@pages/SpaceSettings/interfaces';

interface IParams {
  order_uid: string;
  kind: ActionKind;
  data: ApplyActionSchema;
}

export const useApplyActionQuery = () => {
  const client = useQueryClient();
  const commonT = useTranslation(NSKeys.common);
  const orderT = useTranslation(NSKeys.order);
  const getSuccessMessage = (kind: ActionKind) => {
    switch (kind) {
      case ActionKind.ApproveOrder:
        return orderT.t('successApprove');
      case ActionKind.MarkAsNeedsWork:
        return orderT.t('successMarkAsNeedWork');
      case ActionKind.ApprovalCancelOrder:
        return orderT.t('successCancel');
      default:
        return '';
    }
  };

  return useMutation({
    mutationFn: async ({ order_uid, data, kind }: IParams) => {
      await Api.orders.applyAction(data, order_uid, kind);
    },
    onSuccess: async (_, { order_uid, kind }) => {
      await client.invalidateQueries(['getOrder', order_uid]);
      message.open({
        type: 'success',
        content: getSuccessMessage(kind),
      });
    },
    onError: () => {
      message.open({
        type: 'error',
        content: commonT.t('error400'),
      });
    },
  });
};
