/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** AccessSettingsArtifactSchema */
export interface AccessSettingsArtifactSchema {
  /** Uid */
  uid: string;
  /** Type */
  type: string;
}

/** AccessSettingsFieldGroupSchema */
export interface AccessSettingsFieldGroupSchema {
  /** Uid */
  uid: string;
  /** Alias */
  alias: string | null;
  /** Name */
  name: string;
}

/** ActionKind */
export enum ActionKind {
  ApproveOrder = 'approve-order',
  MarkAsNeedsWork = 'mark-as-needs-work',
  ApprovalCancelOrder = 'approval-cancel-order',
}

/** AdaptedOrderFieldDTO */
export interface AdaptedOrderFieldDTO {
  /** Field Uid */
  field_uid: string;
  data: ImportedDataDTO;
}

/** AdaptedOrderItemDTO */
export interface AdaptedOrderItemDTO {
  product: ImportedDataDTO;
  quantity: ImportedDataDTO;
}

/** AddModuleSchema */
export interface AddModuleSchema {
  /** Module */
  module:
    | ({
        kind: 'approval';
      } & ApprovalModuleInfoInput)
    | ({
        kind: 'final_state';
      } & FinalStateModuleInfoInput);
}

/** Address */
export interface Address {
  /** Uid */
  uid: string;
  /** As String */
  as_string: string;
  provider?: AddressProvider | null;
  /** Provider Address Uid */
  provider_address_uid?: string | null;
  /** Country Name */
  country_name?: string;
  /** Country Iso Code */
  country_iso_code?: string;
  /** Region Name */
  region_name?: string;
  /** City Name */
  city_name?: string;
  /** Postal Code */
  postal_code?: string;
  /** Street Name */
  street_name?: string;
  /** Street Number */
  street_number?: string | null;
  /** Place Name */
  place_name?: string | null;
  /** Lat */
  lat?: number | null;
  /** Lon */
  lon?: number | null;
}

/** AddressForValidation */
export interface AddressForValidation {
  provider: AddressProvider;
  /** Provider Address Uid */
  provider_address_uid: string;
  /** Street Name */
  street_name: string;
  /** Street Number */
  street_number: string | null;
  /**
   * Place Name
   * Идентификатор помещения. Номер квартиры или номер офиса, например.
   */
  place_name: string | null;
}

/** AddressProvider */
export enum AddressProvider {
  Google = 'google',
  Mapbox = 'mapbox',
  Dadata = 'dadata',
}

/** ApplyActionSchema */
export interface ApplyActionSchema {
  /** Payload */
  payload: CancelOrderParamsDTO | ApproveOrderParamsDTO | MarkOrderAsNeedsWorkParamsDTO;
}

/** ApprovalModuleInfo */
export interface ApprovalModuleInfoInput {
  /** Uid */
  uid?: string;
  /**
   * Kind
   * @default "approval"
   */
  kind?: 'approval';
  settings?: ApprovalModuleSettings;
  info?: ApprovalSavedInfo;
}

/** ApprovalModuleInfo */
export interface ApprovalModuleInfoOutput {
  /** Uid */
  uid?: string;
  /**
   * Kind
   * @default "approval"
   */
  kind?: 'approval';
  settings?: ApprovalModuleSettings;
  info?: ApprovalSavedInfo;
  /** Is Removable */
  is_removable: boolean;
}

/** ApprovalModuleSettings */
export type ApprovalModuleSettings = object;

/** ApprovalSavedInfo */
export interface ApprovalSavedInfo {
  /** Stage Uid */
  stage_uid?: string | null;
}

/** ApprovalStepSchema */
export interface ApprovalStepSchema {
  user_group: UserGroupSchema;
  /** Is Passed */
  is_passed: boolean;
  /** Is Current */
  is_current: boolean;
}

/** ApproveOrderParamsDTO */
export interface ApproveOrderParamsDTO {
  /**
   * Version
   * Текущая версия заказа
   */
  version: string;
  /** Comment */
  comment?: string | null;
}

/** ArtifactAccessPolicySchema */
export interface ArtifactAccessPolicySchema {
  /** Type */
  type: 'artifact';
  /** Available Levels */
  available_levels: string[];
  /**
   * Access
   * access level map, stage_uid -> access level
   */
  access: Record<string, string>;
  artifact: AccessSettingsArtifactSchema;
}

/** ArtifactItemSchema */
export interface ArtifactItemSchema {
  /** Type */
  type: 'artifact';
  data: ArtifactSchema;
}

/** ArtifactSchema */
export interface ArtifactSchema {
  /** Uid */
  uid: string;
  /** Type */
  type: 'approval_route' | 'order_items';
}

/** AttributeMetaSchema */
export interface AttributeMetaSchema {
  /** Attr */
  attr: string;
  /** Name Name */
  name_name: string;
}

/** AvailableStatusSchema */
export interface AvailableStatusSchema {
  /** Uid */
  uid: string;
  /** Alias */
  alias: string;
}

/** Body_uploadOrderImage */
export interface BodyUploadOrderImage {
  /**
   * Media
   * Image file
   * @format binary
   */
  media: File;
}

/** Body_uploadOrderImportFile */
export interface BodyUploadOrderImportFile {
  /**
   * File
   * Excel file
   * @format binary
   */
  file: File;
}

/** CancelOrderParamsDTO */
export interface CancelOrderParamsDTO {
  /**
   * Version
   * Текущая версия заказа
   */
  version: string;
  /** Comment */
  comment: string;
}

/** CatalogDTO */
export interface CatalogDTO {
  /** Uid */
  uid: string;
  /** Name */
  name: string;
}

/** CatalogSchema */
export interface CatalogSchema {
  /** Uid */
  uid: string;
  /** Name */
  name: string;
  cover: Image | null;
}

/** CellImportSchema */
export interface CellImportSchema {
  /** Cell */
  cell: string;
}

/** Contact */
export interface Contact {
  /** Uid */
  uid: string;
  /** Name */
  name: string;
  /** Email */
  email: string | null;
  /** Phone */
  phone: string;
}

/** ContextSchema */
export interface ContextSchema {
  scope: ContextScopeSchema;
}

/** ContextScopeSchema */
export interface ContextScopeSchema {
  /** ScopePermissions */
  $permissions: {
    /** View Orders */
    view_orders?: boolean;
    /** Export Orders */
    export_orders?: boolean;
    /** Import Orders */
    import_orders?: boolean;
    /** View Import Orders */
    view_import_orders?: boolean;
    /** View Processes */
    view_processes?: boolean;
    /** Create Processes */
    create_processes?: boolean;
  };
}

/** CreateAccessSettingsDTO */
export interface CreateAccessSettingsDTO {
  user_group: ObjectRef;
}

/** CreateAddressFieldSchema */
export interface CreateAddressFieldSchema {
  /** Type */
  type: 'address';
  /** Name */
  name: string;
  /** Is Required */
  is_required: boolean;
  /** Settings */
  settings?: null;
}

/** CreateBoolFieldSchema */
export interface CreateBoolFieldSchema {
  /** Type */
  type: 'bool';
  /** Name */
  name: string;
  /** Is Required */
  is_required: boolean;
  /** Settings */
  settings?: null;
}

/** CreateCatalogFieldSchema */
export interface CreateCatalogFieldSchema {
  /** Type */
  type: 'catalog';
  /** Name */
  name: string;
  /** Is Required */
  is_required: boolean;
  /** Settings */
  settings?: null;
}

/** CreateContactFieldSchema */
export interface CreateContactFieldSchema {
  /** Type */
  type: 'contact';
  /** Name */
  name: string;
  /** Is Required */
  is_required: boolean;
  /** Settings */
  settings?: null;
}

/** CreateDateFieldSchema */
export interface CreateDateFieldSchema {
  /** Type */
  type: 'date';
  /** Name */
  name: string;
  /** Is Required */
  is_required: boolean;
  /** Settings */
  settings?: null;
}

/** CreateDatetimeFieldSchema */
export interface CreateDatetimeFieldSchema {
  /** Type */
  type: 'datetime';
  /** Name */
  name: string;
  /** Is Required */
  is_required: boolean;
  /** Settings */
  settings?: null;
}

/** CreateFieldGroupSchema */
export interface CreateFieldGroupSchema {
  /** Name */
  name: string;
}

/** CreateMulti_imageFieldSchema */
export interface CreateMultiImageFieldSchema {
  /** Type */
  type: 'multi_image';
  /** Name */
  name: string;
  /** Is Required */
  is_required: boolean;
  /** Settings */
  settings?: null;
}

/** CreateNumFieldSchema */
export interface CreateNumFieldSchema {
  /** Type */
  type: 'num';
  /** Name */
  name: string;
  /** Is Required */
  is_required: boolean;
  /** Settings */
  settings?: null;
}

/** CreateOrderDTO */
export interface CreateOrderDTO {
  /** Field Groups */
  field_groups?: Record<string, object>;
}

/** CreateProcessDTO */
export interface CreateProcessDTO {
  /** Name */
  name: string;
}

/** CreateRecipientFieldSchema */
export interface CreateRecipientFieldSchema {
  /** Type */
  type: 'recipient';
  /** Name */
  name: string;
  /** Is Required */
  is_required: boolean;
  /** Settings */
  settings?: null;
}

/** CreateStageDTO */
export interface CreateStageDTO {
  /** Name */
  name: string;
  /**
   * Position
   * @min 1
   */
  position: number;
}

/** CreateStageStatusDTO */
export interface CreateStageStatusDTO {
  /** Name */
  name: string;
  /**
   * Position
   * @min 1
   */
  position: number;
}

/** CreateStringFieldSchema */
export interface CreateStringFieldSchema {
  /** Type */
  type: 'string';
  /** Name */
  name: string;
  /** Is Required */
  is_required: boolean;
  /** Settings */
  settings?: null;
}

/** CreateTextFieldSchema */
export interface CreateTextFieldSchema {
  /** Type */
  type: 'text';
  /** Name */
  name: string;
  /** Is Required */
  is_required: boolean;
  /** Settings */
  settings?: null;
}

/** CreateUserFieldSchema */
export interface CreateUserFieldSchema {
  /** Type */
  type: 'user';
  /** Name */
  name: string;
  /** Is Required */
  is_required: boolean;
  /** Settings */
  settings?: null;
}

/** DefaultKind */
export enum DefaultKind {
  EndToEnd = 'end_to_end',
  WithoutProduction = 'without_production',
}

/** DeliveryInfo */
export interface DeliveryInfo {
  /** Получатель с адресом */
  recipient: Recipient;
  /** Контактная информация */
  contact: Contact;
  /**
   * Delivery Date
   * Дата доставки
   * @format date
   */
  delivery_date: string;
  /**
   * Comment
   * Комментарий
   */
  comment?: string | null;
  address: Address;
}

/** FieldGroupAccessPolicySchema */
export interface FieldGroupAccessPolicySchema {
  /** Type */
  type: 'field_group';
  /** Available Levels */
  available_levels: string[];
  /**
   * Access
   * access level map, stage_uid -> access level
   */
  access: Record<string, string>;
  field_group: AccessSettingsFieldGroupSchema;
}

/** FieldGroupItemSchema */
export interface FieldGroupItemSchema {
  /** Type */
  type: 'field_group';
  data: FieldGroupSchema;
}

/** FieldGroupSchema */
export interface FieldGroupSchema {
  /** Uid */
  uid: string;
  /** Name */
  name: string;
  /** Is System */
  is_system: boolean;
  /** Alias */
  alias: string | null;
  /** Fields */
  fields: (
    | ({
        type: 'address';
      } & RetrieveAddressFieldSchema)
    | ({
        type: 'bool';
      } & RetrieveBoolFieldSchema)
    | ({
        type: 'catalog';
      } & RetrieveCatalogFieldSchema)
    | ({
        type: 'contact';
      } & RetrieveContactFieldSchema)
    | ({
        type: 'date';
      } & RetrieveDateFieldSchema)
    | ({
        type: 'datetime';
      } & RetrieveDatetimeFieldSchema)
    | ({
        type: 'multi_image';
      } & RetrieveMultiImageFieldSchema)
    | ({
        type: 'num';
      } & RetrieveNumFieldSchema)
    | ({
        type: 'recipient';
      } & RetrieveRecipientFieldSchema)
    | ({
        type: 'string';
      } & RetrieveStringFieldSchema)
    | ({
        type: 'text';
      } & RetrieveTextFieldSchema)
    | ({
        type: 'user';
      } & RetrieveUserFieldSchema)
  )[];
}

/** FinalStateModuleInfo */
export interface FinalStateModuleInfoInput {
  /** Uid */
  uid?: string;
  /**
   * Kind
   * @default "final_state"
   */
  kind?: 'final_state';
  settings?: FinalStateModuleSettings;
  info?: FinalStateSavedInfo;
}

/** FinalStateModuleInfo */
export interface FinalStateModuleInfoOutput {
  /** Uid */
  uid?: string;
  /**
   * Kind
   * @default "final_state"
   */
  kind?: 'final_state';
  settings?: FinalStateModuleSettings;
  info?: FinalStateSavedInfo;
  /** Is Removable */
  is_removable: boolean;
}

/** FinalStateModuleSettings */
export type FinalStateModuleSettings = object;

/** FinalStateSavedInfo */
export interface FinalStateSavedInfo {
  /** Stage Uid */
  stage_uid?: string | null;
}

/** GetMappingSettingsParamsDTO */
export interface GetMappingSettingsParamsDTO {
  /** Catalog Uid */
  catalog_uid: string;
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[];
}

/** HistoryOrderStateSchema */
export interface HistoryOrderStateSchema {
  delivery_info: DeliveryInfo;
  order_total: OrderTotal;
}

/** Image */
export interface Image {
  /** Uid */
  uid: string;
  /** Variants */
  variants: Record<string, ImageVariant>;
}

/** ImageVariant */
export interface ImageVariant {
  /** Url */
  url: string;
}

/** ImportFieldDTO */
export interface ImportFieldDTO {
  /** Uid */
  uid?: string;
  /** Name */
  name: string;
  /** Is Required */
  is_required: boolean;
  /** Field Group Key */
  field_group_key: string;
  /** Field Key */
  field_key: string;
  /** Field Type */
  field_type: string;
}

/** ImportedDataDTO */
export interface ImportedDataDTO {
  /** Result */
  result: any;
  /** Initial */
  initial: any;
  /** Is Success */
  is_success: boolean;
}

/** InvalidOrderStatusError */
export interface InvalidOrderStatusError {
  /** Error Id */
  error_id: string;
  /**
   * Title
   * @default null
   */
  title?: string | null;
  /**
   * Detail
   * @default null
   */
  detail?: string | null;
  /** Code */
  code?: 'order_invalid_status';
  /**
   * Status Code
   * @default null
   */
  status_code?: number | null;
  /** @default null */
  source?: null;
  /**
   * Meta
   * @default null
   */
  meta?: object | null;
}

/** LayoutDTO */
export interface LayoutDTO {
  direction: LayoutDirection;
  orders_region: RegionDTO;
  items_region: RegionDTO;
}

/** LayoutDirection */
export enum LayoutDirection {
  OrdersHorizontal = 'orders_horizontal',
  OrdersVertical = 'orders_vertical',
}

/** MappingAttrDTO */
export interface MappingAttrDTO {
  /** Alias */
  alias: string | null;
  /** Name */
  name: string;
  /** Attr */
  attr: string;
  /**
   * Is Required
   * @default false
   */
  is_required?: boolean;
  /**
   * Is Unique
   * @default false
   */
  is_unique?: boolean;
}

/** MappingFieldDTO */
export interface MappingFieldDTO {
  /** Alias */
  alias: string | null;
  /** Name */
  name: string;
  /** Field */
  field: string;
  /** Is Required */
  is_required: boolean;
  /** Available Attributes */
  available_attributes: MappingAttrDTO[];
}

/** MappingSettingsDTO */
export interface MappingSettingsDTO {
  /** Order Fields */
  order_fields: MappingFieldDTO[];
  /** Items Fields */
  items_fields: MappingFieldDTO[];
}

/** MarkOrderAsNeedsWorkParamsDTO */
export interface MarkOrderAsNeedsWorkParamsDTO {
  /**
   * Version
   * Текущая версия заказа
   */
  version: string;
  /** Comment */
  comment: string;
}

/** MoveToStatusAccessPolicySchema */
export interface MoveToStatusAccessPolicySchema {
  /** Type */
  type: 'move_to_status';
  /** Available Levels */
  available_levels: string[];
  /**
   * Access
   * access level map, stage_uid -> access level
   */
  access: Record<string, string>;
}

/** MoveToStatusSchema */
export interface MoveToStatusSchema {
  /**
   * Version
   * Текущая версия заказа
   */
  version: string;
  /** To Status Uid */
  to_status_uid: string;
}

/** MultipleMoveToStatusSchema */
export interface MultipleMoveToStatusSchema {
  /** To Status Uid */
  to_status_uid: string;
  /** Orders */
  orders: OrderMoveToStatusRef[];
}

/** ObjectRef */
export interface ObjectRef {
  /** Uid */
  uid: string;
}

/** OrderAccessPolicySchema */
export interface OrderAccessPolicySchema {
  /** Type */
  type: 'order';
  /** Available Levels */
  available_levels: string[];
  /**
   * Access
   * access level map, stage_uid -> access level
   */
  access: Record<string, string>;
}

/** OrderApprovedHistoryInfoSchema */
export interface OrderApprovedHistoryInfoSchema {
  /**
   * Kind
   * @default "approved"
   */
  kind?: 'approved';
  /** Comment */
  comment: string | null;
}

/** OrderCancelledHistoryInfoSchema */
export interface OrderCancelledHistoryInfoSchema {
  /**
   * Kind
   * @default "cancelled"
   */
  kind?: 'cancelled';
  /** Comment */
  comment: string | null;
}

/** OrderCreatedHistoryInfoSchema */
export interface OrderCreatedHistoryInfoSchema {
  /**
   * Kind
   * @default "created"
   */
  kind?: 'created';
  order: HistoryOrderStateSchema;
}

/** OrderExportResponseSchema */
export interface OrderExportResponseSchema {
  /**
   * Filename
   * Имя файла экспорта отчетов
   */
  filename: string;
}

/** OrderHistorySchema */
export interface OrderHistorySchema {
  /** Uid */
  uid: string;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  user: UserSchema;
  /**
   * Order Status
   * @deprecated
   */
  order_status: any;
  /** Order Status Uid */
  order_status_uid: string | null;
  /** Info */
  info:
    | ({
        kind: 'approved';
      } & OrderApprovedHistoryInfoSchema)
    | ({
        kind: 'cancelled';
      } & OrderCancelledHistoryInfoSchema)
    | ({
        kind: 'created';
      } & OrderCreatedHistoryInfoSchema)
    | ({
        kind: 'marked_as_needs_work';
      } & OrderMarkedAsNeedsWorkHistoryInfoSchema)
    | ({
        kind: 'moved_to_status';
      } & OrderMovedToStatusHistoryInfoSchema)
    | ({
        kind: 'updated';
      } & OrderUpdatedHistoryInfoSchema);
}

/** OrderImageDTO */
export interface OrderImageDTO {
  /** Uid */
  uid: string;
  variants: OrderImageVariants;
}

/** OrderImageVariants */
export interface OrderImageVariants {
  thumbnail: ImageVariant;
  small: ImageVariant;
  medium: ImageVariant;
  big: ImageVariant;
}

/** OrderImportDataSchema */
export interface OrderImportDataSchema {
  /** Uid */
  uid: string;
  /** Order Import Uid */
  order_import_uid: string;
  /** Order Uid */
  order_uid: string | null;
  /** Order Num */
  order_num: number | null;
  /** Fields */
  fields: AdaptedOrderFieldDTO[];
  /** Items */
  items: AdaptedOrderItemDTO[];
}

/** OrderImportSchema */
export interface OrderImportSchema {
  /** Uid */
  uid: string;
  /**
   * Dt Created
   * @format date-time
   */
  dt_created: string;
  /** Fields */
  fields: ImportFieldDTO[];
  author: UserSchema;
  catalog: CatalogSchema;
  process: SimpleProcessSchema;
  uploaded_file: UploadedFileSchema;
}

/** OrderItemSchema */
export interface OrderItemSchema {
  /** Uid */
  uid: string;
  /** Quantity */
  quantity: number;
  product: SimpleProductSchema;
  /** Volume */
  volume: string;
  /** Price */
  price: string;
  /** Weight */
  weight: string;
}

/** OrderItemsArtifactSchema */
export interface OrderItemsArtifactSchema {
  /** Items */
  items: OrderItemSchema[] | null;
  total: OrderTotal;
  /** Total Items */
  total_items: number;
}

/** OrderMarkedAsNeedsWorkHistoryInfoSchema */
export interface OrderMarkedAsNeedsWorkHistoryInfoSchema {
  /**
   * Kind
   * @default "marked_as_needs_work"
   */
  kind?: 'marked_as_needs_work';
  /** Comment */
  comment: string | null;
}

/** OrderMoveToStatusRef */
export interface OrderMoveToStatusRef {
  /** Uid */
  uid: string;
  /** Version */
  version: string;
}

/** OrderMovedToStatusHistoryInfoSchema */
export interface OrderMovedToStatusHistoryInfoSchema {
  /**
   * Kind
   * @default "moved_to_status"
   */
  kind?: 'moved_to_status';
}

/** OrderOperations */
export interface OrderOperations {
  /** Update */
  update: boolean;
}

/** OrderProcessSchema */
export interface OrderProcessSchema {
  /** Uid */
  uid: string;
  /** Name */
  name: string;
}

/** OrderStageSchema */
export interface OrderStageSchema {
  /** Uid */
  uid: string;
  /** Name */
  name: string;
}

/** OrderStatusSchema */
export interface OrderStatusSchema {
  /** Uid */
  uid: string;
  /** Name */
  name: string;
  /** Alias */
  alias: string;
  stage: OrderStageSchema;
}

/** OrderStructureSchema */
export interface OrderStructureSchema {
  /** Uid */
  uid: string;
  /** Items */
  items: (ArtifactItemSchema | FieldGroupItemSchema)[];
  /** OrderStructurePermissions */
  $permissions: {
    /** View */
    view?: boolean;
    /** Manage */
    manage?: boolean;
  };
}

/** OrderTotal */
export interface OrderTotal {
  /**
   * Quantity
   * @default 0
   */
  quantity?: number;
  /**
   * Volume
   * @default "0"
   */
  volume?: string;
  /**
   * Price
   * @default "0"
   */
  price?: string;
  /**
   * Weight
   * @default "0"
   */
  weight?: string;
}

/** OrderUpdatedHistoryInfoSchema */
export interface OrderUpdatedHistoryInfoSchema {
  /**
   * Kind
   * @default "updated"
   */
  kind?: 'updated';
  before: HistoryOrderStateSchema;
  after: HistoryOrderStateSchema;
}

/** OrderVersionMismatchError */
export interface OrderVersionMismatchError {
  /** Error Id */
  error_id: string;
  /**
   * Title
   * @default null
   */
  title?: string | null;
  /**
   * Detail
   * @default null
   */
  detail?: string | null;
  /** Code */
  code?: 'order_version_mismatch';
  /**
   * Status Code
   * @default null
   */
  status_code?: number | null;
  /** @default null */
  source?: null;
  /**
   * Meta
   * @default null
   */
  meta?: object | null;
}

/** PartialUpdateStageSchema */
export interface PartialUpdateStageSchema {
  /** Name */
  name?: string;
  /** Module */
  module?: UpdateModuleSettingsSchema;
}

/** PartialUpdateStageStatusSchema */
export interface PartialUpdateStageStatusSchema {
  /** Name */
  name?: string;
  /**
   * Color
   * @format color
   */
  color?: string;
}

/** PreviewDTO */
export interface PreviewDTO {
  /** Orders */
  orders: object[];
  /** Items */
  items: object[];
}

/** PreviewParamsDTO */
export interface PreviewParamsDTO {
  /** Uploaded File Uid */
  uploaded_file_uid: string;
  layout: LayoutDTO;
}

/** ProcessAccessSettingsSchema */
export interface ProcessAccessSettingsSchema {
  /** Uid */
  uid: string;
  user_group: UserGroupSchema;
  /** Policies */
  policies: (
    | ({
        type: 'artifact';
      } & ArtifactAccessPolicySchema)
    | ({
        type: 'field_group';
      } & FieldGroupAccessPolicySchema)
    | ({
        type: 'move_to_status';
      } & MoveToStatusAccessPolicySchema)
    | ({
        type: 'order';
      } & OrderAccessPolicySchema)
  )[];
  /** ProcessAccessSettingsPermissions */
  $permissions: {
    /** Manage */
    manage?: boolean;
  };
}

/** ProcessStageSchema */
export interface ProcessStageSchema {
  /** Uid */
  uid: string;
  /** Name */
  name: string;
  /** Position */
  position: number;
  /** Is Editable */
  is_editable: boolean;
  /** Statuses */
  statuses: ProcessStageStatusSchema[];
}

/** ProcessStageStatusSchema */
export interface ProcessStageStatusSchema {
  /** Uid */
  uid: string;
  /** Name */
  name: string;
  /** Alias */
  alias: string | null;
  /** Position */
  position: number;
  /**
   * Color
   * @format color
   */
  color: string;
}

/** ProductImportError */
export interface ProductImportError {
  /** Error Id */
  error_id: string;
  /** Title */
  title?: string | null;
  /** Detail */
  detail?: string | null;
  /** Code */
  code?: 'product_import_error';
  /** Status Code */
  status_code?: number | null;
  source?: Source | null;
  meta: CellImportSchema;
}

/** QuantityImportError */
export interface QuantityImportError {
  /** Error Id */
  error_id: string;
  /** Title */
  title?: string | null;
  /** Detail */
  detail?: string | null;
  /** Code */
  code?: 'quantity_import_error';
  /** Status Code */
  status_code?: number | null;
  source?: Source | null;
  meta: CellImportSchema;
}

/** RearrangeFieldDTO */
export interface RearrangeFieldDTO {
  /** Uid */
  uid: string;
  /** Position */
  position: number;
}

/** RearrangeStagesDTO */
export interface RearrangeStagesDTO {
  /** Uid */
  uid: string;
  /**
   * Position
   * @min 1
   */
  position: number;
}

/** RearrangeStatusesDTO */
export interface RearrangeStatusesDTO {
  /** Uid */
  uid: string;
  /**
   * Position
   * @min 1
   */
  position: number;
}

/** Recipient */
export interface Recipient {
  /** Uid */
  uid: string;
  /** Name */
  name: string;
  /** Code */
  code: string | null;
  address: Address;
}

/** RecordsListGenericSchema[OrderImportDataSchema] */
export interface RecordsListGenericSchemaOrderImportDataSchema {
  /** Items */
  items: OrderImportDataSchema[];
  /**
   * Total
   * @default 0
   */
  total?: number;
}

/** RecordsListGenericSchema[OrderImportSchema] */
export interface RecordsListGenericSchemaOrderImportSchema {
  /** Items */
  items: OrderImportSchema[];
  /**
   * Total
   * @default 0
   */
  total?: number;
}

/** RecordsListGenericSchema[RetrieveOrderSchema] */
export interface RecordsListGenericSchemaRetrieveOrderSchema {
  /** Items */
  items: RetrieveOrderSchema[];
  /**
   * Total
   * @default 0
   */
  total?: number;
}

/** RecordsListGenericSchema[SimpleProcessSchema] */
export interface RecordsListGenericSchemaSimpleProcessSchema {
  /** Items */
  items: SimpleProcessSchema[];
  /**
   * Total
   * @default 0
   */
  total?: number;
}

/** RegionDTO */
export interface RegionDTO {
  /**
   * Region Start
   * @pattern ^(([A-Za-z]{1,3})(\d+))?$
   */
  region_start: string;
  /** Region End */
  region_end?: string | null;
  /**
   * Size
   * @default 1
   */
  size?: number;
  /** Mapping */
  mapping?: object;
}

/** RemoveModuleSchema */
export interface RemoveModuleSchema {
  module: ObjectRef;
}

/** RequiredMappingAttributeMissing */
export interface RequiredMappingAttributeMissing {
  /** Error Id */
  error_id: string;
  /** Title */
  title?: string | null;
  /** Detail */
  detail?: string | null;
  /** Code */
  code?: 'required_mapping_attribute_missing';
  /** Status Code */
  status_code?: number | null;
  source?: Source | null;
  meta: RequiredMappingAttributeMissingMeta;
}

/** RequiredMappingAttributeMissingMeta */
export interface RequiredMappingAttributeMissingMeta {
  /** Field */
  field: string;
  /** Field Name */
  field_name: string;
  /** Variants */
  variants: AttributeMetaSchema[];
}

/** RequiredMappingFieldMissing */
export interface RequiredMappingFieldMissing {
  /** Error Id */
  error_id: string;
  /** Title */
  title?: string | null;
  /** Detail */
  detail?: string | null;
  /** Code */
  code?: 'required_mapping_field_missing';
  /** Status Code */
  status_code?: number | null;
  source?: Source | null;
  meta: RequiredMappingFieldMissingMeta;
}

/** RequiredMappingFieldMissingMeta */
export interface RequiredMappingFieldMissingMeta {
  /** Field */
  field: string;
  /** Field Name */
  field_name: string;
}

/** RetrieveAddressFieldSchema */
export interface RetrieveAddressFieldSchema {
  /** Uid */
  uid: string;
  /** Type */
  type: 'address';
  /** Name */
  name: string;
  /** Read Only */
  read_only: boolean;
  /** Is Required */
  is_required: boolean;
  /** Is System */
  is_system: boolean;
  /** Alias */
  alias: string | null;
  /** Settings */
  settings?: null;
}

/** RetrieveApprovalRouteSchema */
export interface RetrieveApprovalRouteSchema {
  /** Is Finished */
  is_finished: boolean;
  /** Steps */
  steps: ApprovalStepSchema[];
}

/** RetrieveBoolFieldSchema */
export interface RetrieveBoolFieldSchema {
  /** Uid */
  uid: string;
  /** Type */
  type: 'bool';
  /** Name */
  name: string;
  /** Read Only */
  read_only: boolean;
  /** Is Required */
  is_required: boolean;
  /** Is System */
  is_system: boolean;
  /** Alias */
  alias: string | null;
  /** Settings */
  settings?: null;
}

/** RetrieveCatalogFieldSchema */
export interface RetrieveCatalogFieldSchema {
  /** Uid */
  uid: string;
  /** Type */
  type: 'catalog';
  /** Name */
  name: string;
  /** Read Only */
  read_only: boolean;
  /** Is Required */
  is_required: boolean;
  /** Is System */
  is_system: boolean;
  /** Alias */
  alias: string | null;
  /** Settings */
  settings?: null;
}

/** RetrieveContactFieldSchema */
export interface RetrieveContactFieldSchema {
  /** Uid */
  uid: string;
  /** Type */
  type: 'contact';
  /** Name */
  name: string;
  /** Read Only */
  read_only: boolean;
  /** Is Required */
  is_required: boolean;
  /** Is System */
  is_system: boolean;
  /** Alias */
  alias: string | null;
  /** Settings */
  settings?: null;
}

/** RetrieveDateFieldSchema */
export interface RetrieveDateFieldSchema {
  /** Uid */
  uid: string;
  /** Type */
  type: 'date';
  /** Name */
  name: string;
  /** Read Only */
  read_only: boolean;
  /** Is Required */
  is_required: boolean;
  /** Is System */
  is_system: boolean;
  /** Alias */
  alias: string | null;
  /** Settings */
  settings?: null;
}

/** RetrieveDatetimeFieldSchema */
export interface RetrieveDatetimeFieldSchema {
  /** Uid */
  uid: string;
  /** Type */
  type: 'datetime';
  /** Name */
  name: string;
  /** Read Only */
  read_only: boolean;
  /** Is Required */
  is_required: boolean;
  /** Is System */
  is_system: boolean;
  /** Alias */
  alias: string | null;
  /** Settings */
  settings?: null;
}

/** RetrieveMulti_imageFieldSchema */
export interface RetrieveMultiImageFieldSchema {
  /** Uid */
  uid: string;
  /** Type */
  type: 'multi_image';
  /** Name */
  name: string;
  /** Read Only */
  read_only: boolean;
  /** Is Required */
  is_required: boolean;
  /** Is System */
  is_system: boolean;
  /** Alias */
  alias: string | null;
  /** Settings */
  settings?: null;
}

/** RetrieveNumFieldSchema */
export interface RetrieveNumFieldSchema {
  /** Uid */
  uid: string;
  /** Type */
  type: 'num';
  /** Name */
  name: string;
  /** Read Only */
  read_only: boolean;
  /** Is Required */
  is_required: boolean;
  /** Is System */
  is_system: boolean;
  /** Alias */
  alias: string | null;
  /** Settings */
  settings?: null;
}

/** RetrieveOrderSchema */
export interface RetrieveOrderSchema {
  /** Uid */
  uid: string;
  /** Num */
  num: number;
  /** Version */
  version: string;
  process: OrderProcessSchema | null;
  current_status: OrderStatusSchema | null;
  /** Current Status Uid */
  current_status_uid: string;
  $operations: OrderOperations;
  /** $Available Statuses */
  $available_statuses: AvailableStatusSchema[];
  /** $Actions */
  $actions: ActionKind[];
  /** $Field Groups */
  $field_groups: Record<string, SimplePermissionSchema> | null;
  /** Field Groups */
  field_groups: Record<string, object | null> | null;
  /** Artifacts */
  artifacts: Record<string, object | null> | null;
  /** $Artifacts */
  $artifacts: Record<string, SimplePermissionSchema> | null;
  /** OrderPermissions */
  $permissions: {
    /** Cancel */
    cancel?: boolean;
    /** View */
    view?: boolean;
    /** Update */
    update?: boolean;
    /** Move To Status */
    move_to_status?: boolean;
  };
}

/** RetrieveProcessSchema */
export interface RetrieveProcessSchema {
  /** Uid */
  uid: string;
  /** Name */
  name: string;
  /** Stages */
  stages: ProcessStageSchema[];
  /** Modules */
  modules: (
    | ({
        kind: 'approval';
      } & ApprovalModuleInfoOutput)
    | ({
        kind: 'final_state';
      } & FinalStateModuleInfoOutput)
  )[];
  order_structure: OrderStructureSchema;
  /** ProcessPermissions */
  $permissions: {
    /** Create Access Settings */
    create_access_settings?: boolean;
    /** Manage */
    manage?: boolean;
    /** View */
    view?: boolean;
    /** View Access Settings */
    view_access_settings?: boolean;
    /** View Order Structure */
    view_order_structure?: boolean;
  };
}

/** RetrieveRecipientFieldSchema */
export interface RetrieveRecipientFieldSchema {
  /** Uid */
  uid: string;
  /** Type */
  type: 'recipient';
  /** Name */
  name: string;
  /** Read Only */
  read_only: boolean;
  /** Is Required */
  is_required: boolean;
  /** Is System */
  is_system: boolean;
  /** Alias */
  alias: string | null;
  /** Settings */
  settings?: null;
}

/** RetrieveStringFieldSchema */
export interface RetrieveStringFieldSchema {
  /** Uid */
  uid: string;
  /** Type */
  type: 'string';
  /** Name */
  name: string;
  /** Read Only */
  read_only: boolean;
  /** Is Required */
  is_required: boolean;
  /** Is System */
  is_system: boolean;
  /** Alias */
  alias: string | null;
  /** Settings */
  settings?: null;
}

/** RetrieveTextFieldSchema */
export interface RetrieveTextFieldSchema {
  /** Uid */
  uid: string;
  /** Type */
  type: 'text';
  /** Name */
  name: string;
  /** Read Only */
  read_only: boolean;
  /** Is Required */
  is_required: boolean;
  /** Is System */
  is_system: boolean;
  /** Alias */
  alias: string | null;
  /** Settings */
  settings?: null;
}

/** RetrieveUserFieldSchema */
export interface RetrieveUserFieldSchema {
  /** Uid */
  uid: string;
  /** Type */
  type: 'user';
  /** Name */
  name: string;
  /** Read Only */
  read_only: boolean;
  /** Is Required */
  is_required: boolean;
  /** Is System */
  is_system: boolean;
  /** Alias */
  alias: string | null;
  /** Settings */
  settings?: null;
}

/** RunOrderImportParamsDTO */
export interface RunOrderImportParamsDTO {
  /** Catalog Uid */
  catalog_uid: string;
  /** Uploaded File Uid */
  uploaded_file_uid: string;
  layout: LayoutDTO;
}

/** SimpleFieldGroupSchema */
export interface SimpleFieldGroupSchema {
  /** Uid */
  uid: string;
  /** Name */
  name: string;
  /** Is System */
  is_system: boolean;
  /** Alias */
  alias: string | null;
}

/** SimplePermissionSchema */
export interface SimplePermissionSchema {
  /**
   * View
   * @default false
   */
  view?: boolean;
  /**
   * Update
   * @default false
   */
  update?: boolean;
}

/** SimpleProcessSchema */
export interface SimpleProcessSchema {
  /** Uid */
  uid: string;
  /** Name */
  name: string;
}

/** SimpleProductSchema */
export interface SimpleProductSchema {
  /** Uid */
  uid: string;
  /**
   * Title
   * Название
   */
  title: string;
  /**
   * Code
   * Артикул
   */
  code: string | null;
  /**
   * Price
   * Стоимость
   */
  price: string | null;
  /**
   * Package Amount
   * Кратность упаковки
   */
  package_amount: number | null;
}

/** UniqueMappingAttributeMissing */
export interface UniqueMappingAttributeMissing {
  /** Error Id */
  error_id: string;
  /** Title */
  title?: string | null;
  /** Detail */
  detail?: string | null;
  /** Code */
  code?: 'unique_mapping_attribute_missing';
  /** Status Code */
  status_code?: number | null;
  source?: Source | null;
  /** Meta */
  meta?: object | null;
}

/** UpdateAddressFieldSchema */
export interface UpdateAddressFieldSchema {
  /** Type */
  type: 'address';
  /** Name */
  name: string;
  /** Is Required */
  is_required: boolean;
  /** Settings */
  settings?: null;
}

/** UpdateArtifactAccessPolicyDTO */
export interface UpdateArtifactAccessPolicyDTO {
  /** Type */
  type: 'artifact';
  /** Access */
  access: Record<string, string>;
  artifact: ObjectRef;
}

/** UpdateBoolFieldSchema */
export interface UpdateBoolFieldSchema {
  /** Type */
  type: 'bool';
  /** Name */
  name: string;
  /** Is Required */
  is_required: boolean;
  /** Settings */
  settings?: null;
}

/** UpdateCatalogFieldSchema */
export interface UpdateCatalogFieldSchema {
  /** Type */
  type: 'catalog';
  /** Name */
  name: string;
  /** Is Required */
  is_required: boolean;
  /** Settings */
  settings?: null;
}

/** UpdateContactFieldSchema */
export interface UpdateContactFieldSchema {
  /** Type */
  type: 'contact';
  /** Name */
  name: string;
  /** Is Required */
  is_required: boolean;
  /** Settings */
  settings?: null;
}

/** UpdateDateFieldSchema */
export interface UpdateDateFieldSchema {
  /** Type */
  type: 'date';
  /** Name */
  name: string;
  /** Is Required */
  is_required: boolean;
  /** Settings */
  settings?: null;
}

/** UpdateDatetimeFieldSchema */
export interface UpdateDatetimeFieldSchema {
  /** Type */
  type: 'datetime';
  /** Name */
  name: string;
  /** Is Required */
  is_required: boolean;
  /** Settings */
  settings?: null;
}

/** UpdateFieldGroupAccessPolicyDTO */
export interface UpdateFieldGroupAccessPolicyDTO {
  /** Type */
  type: 'field_group';
  /** Access */
  access: Record<string, string>;
  field_group: ObjectRef;
}

/** UpdateFieldGroupSchema */
export interface UpdateFieldGroupSchema {
  /** Name */
  name: string;
}

/**
 * UpdateModuleSettingsSchema
 * Can't change module kind of stage.
 */
export interface UpdateModuleSettingsSchema {
  /** Settings */
  settings: ApprovalModuleSettings | FinalStateModuleSettings;
}

/** UpdateMoveToStatusAccessPolicyDTO */
export interface UpdateMoveToStatusAccessPolicyDTO {
  /** Type */
  type: 'move_to_status';
  /** Access */
  access: Record<string, string>;
}

/** UpdateMulti_imageFieldSchema */
export interface UpdateMultiImageFieldSchema {
  /** Type */
  type: 'multi_image';
  /** Name */
  name: string;
  /** Is Required */
  is_required: boolean;
  /** Settings */
  settings?: null;
}

/** UpdateNumFieldSchema */
export interface UpdateNumFieldSchema {
  /** Type */
  type: 'num';
  /** Name */
  name: string;
  /** Is Required */
  is_required: boolean;
  /** Settings */
  settings?: null;
}

/** UpdateOrderAccessPolicyDTO */
export interface UpdateOrderAccessPolicyDTO {
  /** Type */
  type: 'order';
  /** Access */
  access: Record<string, string>;
}

/** UpdateOrderItemDTO */
export interface UpdateOrderItemDTO {
  /** Product Uid */
  product_uid: string;
  /**
   * Quantity
   * @min 1
   */
  quantity: number;
}

/** UpdateOrderItemsArtifactDTO */
export interface UpdateOrderItemsArtifactDTO {
  /** Items */
  items: UpdateOrderItemDTO[];
}

/** UpdateOrderParamsDTO */
export interface UpdateOrderParamsDTO {
  /**
   * Version
   * Текущая версия заказа
   */
  version: string;
  /** Field Groups */
  field_groups?: Record<string, object>;
  /** Artifacts */
  artifacts?: Record<string, object>;
}

/** UpdateProcessAccessSettingsDTO */
export interface UpdateProcessAccessSettingsDTO {
  /** Policies */
  policies: (
    | ({
        type: 'artifact';
      } & UpdateArtifactAccessPolicyDTO)
    | ({
        type: 'field_group';
      } & UpdateFieldGroupAccessPolicyDTO)
    | ({
        type: 'move_to_status';
      } & UpdateMoveToStatusAccessPolicyDTO)
    | ({
        type: 'order';
      } & UpdateOrderAccessPolicyDTO)
  )[];
}

/** UpdateRecipientFieldSchema */
export interface UpdateRecipientFieldSchema {
  /** Type */
  type: 'recipient';
  /** Name */
  name: string;
  /** Is Required */
  is_required: boolean;
  /** Settings */
  settings?: null;
}

/** UpdateStringFieldSchema */
export interface UpdateStringFieldSchema {
  /** Type */
  type: 'string';
  /** Name */
  name: string;
  /** Is Required */
  is_required: boolean;
  /** Settings */
  settings?: null;
}

/** UpdateTextFieldSchema */
export interface UpdateTextFieldSchema {
  /** Type */
  type: 'text';
  /** Name */
  name: string;
  /** Is Required */
  is_required: boolean;
  /** Settings */
  settings?: null;
}

/** UpdateUserFieldSchema */
export interface UpdateUserFieldSchema {
  /** Type */
  type: 'user';
  /** Name */
  name: string;
  /** Is Required */
  is_required: boolean;
  /** Settings */
  settings?: null;
}

/** UploadedFileSchema */
export interface UploadedFileSchema {
  /** Uid */
  uid: string;
  /** Filename */
  filename: string;
  /**
   * Dt Uploaded
   * @format date-time
   */
  dt_uploaded: string;
}

/** UploadedImageSchema */
export interface UploadedImageSchema {
  /** Uid */
  uid: string;
  variants: UploadedImageVariantsSchema;
}

/** UploadedImageVariantsSchema */
export interface UploadedImageVariantsSchema {
  thumbnail: ImageVariant;
}

/** UserDTO */
export interface UserDTO {
  /** Id */
  id: number;
  /** Username */
  username: string;
  /** Name */
  name: string;
}

/** UserGroupSchema */
export interface UserGroupSchema {
  /** Uid */
  uid: string;
  /** Name */
  name: string;
}

/** UserRef */
export interface UserRef {
  /** Id */
  id: number;
}

/** UserSchema */
export interface UserSchema {
  /** Id */
  id: number;
  /** Username */
  username: string;
  /** Name */
  name: string;
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: (string | number)[];
  /** Message */
  msg: string;
  /** Error Type */
  type: string;
}

/** _Source */
export interface Source {
  /** Pointer */
  pointer?: string | null;
  /** Header */
  header?: string | null;
  /** Parameter */
  parameter?: string | null;
}

/** InvalidOrderApprovalStepError */
export interface InvalidOrderApprovalStepError {
  /** Error Id */
  error_id: string;
  /**
   * Title
   * @default null
   */
  title?: string | null;
  /**
   * Detail
   * @default null
   */
  detail?: string | null;
  /** Code */
  code?: 'order_invalid_approval_step';
  /**
   * Status Code
   * @default null
   */
  status_code?: number | null;
  /** @default null */
  source?: null;
  /**
   * Meta
   * @default null
   */
  meta?: object | null;
}
