import { ColumnsType } from 'antd/es/table';

import { OrderImportSchema } from '@app/models/orders';
import { formatDateAndTime } from '@app/services/date';

import { DownloadButton } from '../../../components/DownloadButton';

export const BASE_COLUMNS_WIP: ColumnsType<OrderImportSchema> = [
  {
    title: 'tableColumns.dt_created',
    dataIndex: 'dt_created',
    key: 'dt_created',
    ellipsis: true,
    render: (el) => formatDateAndTime(el),
  },
  {
    title: 'tableColumns.author',
    dataIndex: 'author',
    key: 'author',
    render: (_, row) => row.author.name,
    ellipsis: true,
  },
  {
    title: 'tableColumns.catalog',
    dataIndex: 'catalog',
    key: 'catalog',
    render: (_, row) => row.catalog.name,
    ellipsis: true,
  },
  {
    title: 'tableColumns.uploaded_file',
    dataIndex: 'uploaded_file',
    key: 'uploaded_file',
    render: (_, row) => <DownloadButton uid={row.uploaded_file.uid} fileName={row.uploaded_file.filename} />,
    ellipsis: true,
  },
];
