import { useQuery } from '@tanstack/react-query';

import { IOrderImportsParams } from '@app/models/interfaces';
import Api from '@app/services/api';

export const useGetOrderImportsQuery = ({ limit, offset }: IOrderImportsParams) => {
  return useQuery({
    queryKey: ['getOrderImports', limit, offset],
    queryFn: () => Api.orders.getOrderImports({ limit, offset }),
  });
};
