import { AdaptedOrderFieldDTO, OrderImportDataSchema, OrderImportSchema } from '@app/models/orders';

export const getInitialValues = (orderData: OrderImportDataSchema, orderImport: OrderImportSchema) => {
  const findData = (fieldData?: AdaptedOrderFieldDTO): any => {
    const fieldInfo = orderImport.fields.find((field) => field.uid === fieldData?.field_uid);
    if (!fieldInfo || !fieldData) return '';

    // console.log(fieldInfo?.name, fieldInfo.uid, fieldInfo?.field_type, fieldData.data);

    switch (fieldInfo.field_type) {
      case 'recipient':
        return fieldData.data.result;
      case 'contact':
        return fieldData.data.result;
      default:
        return fieldData.data.result;
    }
  };

  return orderData.fields.reduce((acc, el) => {
    return { ...acc, [el.field_uid]: findData(el) };
  }, {});
};
