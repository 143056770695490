import { ColumnsType } from 'antd/es/table';

import { Namespace, TFunction } from 'react-i18next';

import { FieldGroupItemSchema, RetrieveOrderSchema, RetrieveProcessSchema } from '@app/models/orders';

import { getDisplayValueKey } from '../../../../../../../data/getDisplayValueKey';
import { ChangeStatus } from '../../../../../components/ChangeStatus';

export const getTableItems = ({
  processData,
  splitFields,
  t,
}: {
  processData?: RetrieveProcessSchema;
  splitFields?: boolean;
  t?: TFunction<Namespace<'orders'>>;
}): ColumnsType<RetrieveOrderSchema> | undefined => {
  const res = processData?.order_structure?.items
    .filter((item) => item.type === 'field_group')
    .map((fieldCommon) =>
      (fieldCommon as FieldGroupItemSchema)?.data?.fields.map((field) => {
        const groupKey =
          (fieldCommon as FieldGroupItemSchema).data.alias || (fieldCommon as FieldGroupItemSchema).data.uid;
        const field_key = field.alias || field.uid;

        return {
          fieldType: field.type,
          groupKey: groupKey,
          is_required: field.is_required,
          is_system: field.is_system,
          read_only: field.read_only,
          key_for_filter: `field_groups.${groupKey}.${field_key}`,
          dataIndex: `field_groups.${groupKey}.${field_key}`,
          key: `field_groups.${groupKey}.${field_key}`,
          width: 120,
          title: field.name,
          render: (_: any, data: any) =>
            getDisplayValueKey({
              type: field.type,
              alias: field_key,
              data: data?.field_groups?.[groupKey],
              renderForTable: true,
            }),
        };
      })
    )
    .flat()
    .reduce((acc, { render, ...value }) => {
      if (value.fieldType === 'contact' && splitFields) {
        return [
          ...acc,
          {
            ...value,
            dataIndex: `${value.dataIndex}.name`,
            key: `${value.dataIndex}.name`,
            key_for_filter: `${value.dataIndex}.uid`,
            title: `${value.title} (${t?.('tableColumns.contactName')})`,
            render: (_: any, data: any) =>
              getDisplayValueKey({
                type: 'text',
                alias: `${value.key}.name`,
                data: data,
                renderForTable: true,
              }),
          },
          {
            ...value,
            dataIndex: `${value.dataIndex}.phone`,
            key: `${value.dataIndex}.phone`,
            key_for_filter: `${value.dataIndex}.phone`,
            title: `${value.title} (${t?.('tableColumns.contactPhone')})`,
            render: (_: any, data: any) =>
              getDisplayValueKey({
                type: 'text',
                alias: `${value.key}.phone`,
                data: data,
                renderForTable: true,
              }),
          },
        ];
      }
      if (value.fieldType === 'catalog') {
        return [
          ...acc,
          {
            ...value,
            dataIndex: `${value.dataIndex}.name`,
            key: `${value.dataIndex}.name`,
            key_for_filter: `${value.dataIndex}.uid`,
            render: (_: any, data: any) =>
              getDisplayValueKey({
                type: 'text',
                alias: `${value.key}.name`,
                data: data,
                renderForTable: true,
              }),
          },
        ];
      }
      if (value.fieldType === 'recipient' && splitFields) {
        return [
          ...acc,
          {
            ...value,
            dataIndex: `${value.dataIndex}.name`,
            key: `${value.dataIndex}.name`,
            key_for_filter: `${value.dataIndex}.name`,
            title: `${value.title} (${t?.('tableColumns.recipientName')})`,
            render: (_: any, data: any) =>
              getDisplayValueKey({
                type: 'text',
                alias: `${value.key}.name`,
                data: data,
                renderForTable: true,
              }),
          },
          {
            ...value,
            dataIndex: `${value.dataIndex}.address.as_string`,
            key: `${value.dataIndex}.address.as_string`,
            key_for_filter: `${value.dataIndex}.address.as_string`,
            title: `${value.title} (${t?.('tableColumns.address')})`,
            render: (_: any, data: any) =>
              getDisplayValueKey({
                type: 'text',
                alias: `${value.key}.address.as_string`,
                data: data,
                renderForTable: true,
              }),
          },
        ];
      }
      if (value.fieldType === 'address') {
        return [
          ...acc,
          {
            ...value,
            key_for_filter: `${value.dataIndex}.as_string`,
            render,
          },
        ];
      }
      return [...acc, { ...value, render }];
    }, [] as any);

  return [
    ...res,
    {
      width: 120,
      title: t?.('tableColumns.status'),
      dataIndex: 'current_status_uid',
      key: 'current_status_uid',
      align: 'center',
      render: (_, { current_status_uid, uid, $available_statuses, $permissions, version }) => (
        <ChangeStatus
          version={version}
          processLoading={false}
          processData={processData}
          moveAvailable={!!$permissions?.move_to_status}
          orderUid={uid}
          statusUid={current_status_uid}
          available_statuses_uids={$available_statuses?.map((el) => el.uid)}
        />
      ),
    },
  ];
};
