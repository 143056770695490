import { useMutation, useQueryClient } from '@tanstack/react-query';

import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import Api from '@app/services/api';

interface IProps {
  data: object;
  order_import_data_uid: string;
}

export const useUpdateOrderImportData = () => {
  const client = useQueryClient();
  const commonT = useTranslation(NSKeys.common);

  return useMutation({
    mutationFn: async ({ data, order_import_data_uid }: IProps) => {
      return await Api.orders.postUpdateOrderImportData(data, order_import_data_uid);
    },
    onError: () => {
      message.open({
        type: 'error',
        content: commonT.t('error400'),
      });
    },
    onSuccess: async () => {
      await client.invalidateQueries(['getOrderImportData']);
      message.open({
        type: 'success',
        content: commonT.t('dataPatchSuccess'),
      });
    },
  });
};
