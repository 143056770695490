import { useQuery } from '@tanstack/react-query';

import { IGetOrdersImportsParams } from '@app/models/interfaces';

import Api from '@app/services/api';

export const useGetOrderImportData = ({ order_import_uid, offset, limit }: IGetOrdersImportsParams) => {
  return useQuery({
    queryKey: ['getOrderImportData', order_import_uid, offset, limit],
    queryFn: () => Api.orders.getOrderImportData({ order_import_uid, limit, offset }),
    enabled: !!order_import_uid,
  });
};
